<template>
    <div class="w-100 d-flex flex-column">
        <div class="text-center">
            <strong class="text-muted mr-2">View all the time &nbsp;</strong>
            <el-switch v-model="viewAllTime" :disabled="isLoadingChart[0] || isLoadingChart[1] || isLoadingChart[2]"></el-switch>
        </div>
        <div class="flex-fill mt-2 w-100" v-loading="isLoadingChart[0]">
            <highcharts class="mt-1 mr-1" :options="top10RegionRevenueAndUser.options"></highcharts>
        </div>
        <div class="flex-fill w-100" id="userRegionCircleChartBlock" v-loading="isLoadingChart[1]">
            <div class="mt-3 text-center"><h3><strong>New user {{(viewAllTime ? "all time" : `last ${numberOfDateView} days`)}}</strong></h3></div>
            <highcharts class="mt-1 mr-1" :options="userRegionCircleChart.options"></highcharts>
        </div>
        <div class="flex-fill w-100" v-loading="isLoadingChart[2]">
            <div class="mt-3 text-center"><h3><strong>Revenue {{(viewAllTime ? "all time" : `last ${numberOfDateView} days`)}}</strong></h3></div>
            <highcharts class="mt-1 mr-1" :options="revenueRegionCircleChart.options"></highcharts>
        </div>
    </div>
</template>

<script>
    import chartDataApi from '@/api/common/chartData';
    import { circleChart } from "@/constant/dashboardChartOptions";
    import { Chart } from 'highcharts-vue';
    import countryList from "@/constant/countryList";
    var numeral = require('numeral');
    export default {
        components: {
            highcharts: Chart
        },
        watch: {
            viewAllTime() {
                this.getTop10RegionRevenueAndUser();
                this.getUserByCountry();
                this.getRevenueByCountry();
            }
        },
        props: {
            numberOfDateView: {
                type: Number,
                default: 30
            },
        },
        data() {
            return {
                isLoadingChart: [false, false, false],
                viewAllTime: true,
                top10RegionRevenueAndUser: {
                    options: {
                        chart: {
                            type: 'column',
                            height: 500
                        },
                        title: {
                            text: 'Top 10 country highest revenue'
                        },
                        xAxis: {
                            categories: []
                        },
                        yAxis: {
                            min: 0,
                            //max: 100,
                            title: {
                                text: 'Proportion (%)'
                            }
                        },
                        tooltip: {
                            //pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.total}</b> ({point.percentage:.0f}%)<br/>',
                            formatter: function () {
                                var s = '<b>' + this.x + '</b>',
                                    sum = 0;

                                $.each(this.points, function (i, point) {
                                    sum += point.y;
                                });

                                $.each(this.points, function (i, point) {
                                    s += `<br/>${point.series.name}: ${numeral(point.y).format('0,00')}%`;
                                });

                                //s += '<br/>Sum: ' + sum

                                return s;
                            },
                            shared: true
                        },
                        plotOptions: {
                            column: {
                                dataLabels: {
                                    formatter: function () {
                                        return `${this.y}%`;
                                    },
                                    enabled: true
                                },
                                stacking: false,
                            },
                            areaspline: {
                                dataLabels: {
                                    formatter: function () {
                                        return `$${this.y}`;
                                    },
                                    enabled: true
                                },
                                stacking: 'percent',
                                lineColor: '#ffffff',
                                lineWidth: 1,
                                marker: {
                                    lineWidth: 1,
                                    lineColor: '#ffffff'
                                },
                            },
                            spline: {
                                dataLabels: {
                                    formatter: function () {
                                        return `${this.y}`;
                                    },
                                    enabled: true
                                },
                                pointPadding: 0.2,
                                borderWidth: 0
                            }
                        },
                        series: []
                    },
                    tableData: []
                },
                userRegionCircleChart: {
                    options: JSON.parse(JSON.stringify(Object.assign(circleChart, {
                        tooltip: {
                            formatter: function () {
                                var value = this.value;
                                return 'The revenue for <b>' + this.x +
                                    '</b> is <b>' + numeral(this.y).format('0,0.[0000]') + 'user</b>';
                            },
                            valueSurfix: 'user'
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true,
                                    format: '<b>{point.name}</b>:  {point.value} {point.percentage:.2f}%',
                                },
                                showInLegend: false
                            }
                        },
                        series: [{
                            name: 'Users',
                            data: []
                        }]
                    }))),
                    tableData: []
                },
                revenueRegionCircleChart: {
                    options: JSON.parse(JSON.stringify(Object.assign(circleChart,
                        {
                            tooltip: {
                                formatter: function () {
                                    var value = this.value;
                                    return 'The revenue for <b>' + (value.region === 'FPTSOFT' ? 'FPT SOFTWARE' : (this.countryList[value.region] ? this.countryList[value.region] : "N/A")) +
                                        '</b> is <b>' + numeral(this.y).format('0,0.[0000]') + '$</b>';
                                },
                                valuePrefix: '$'
                            },
                            plotOptions: {
                                pie: {
                                    allowPointSelect: true,
                                    cursor: 'pointer',
                                    dataLabels: {
                                        enabled: true,
                                        format: '<b>{point.name}</b>: {point.percentage:.2f} %',
                                    },
                                    showInLegend: false
                                }
                            },
                            series: [{
                                name: 'Recharge($)',
                                data: []
                            }]
                        }))),
                },
                countryList: countryList
            };
        },
        mounted() {
            this.getTop10RegionRevenueAndUser();
            this.getUserByCountry();
            this.getRevenueByCountry();
        },
        methods: {
            getTop10RegionRevenueAndUser() {
                this.isLoadingChart[0] = true;
                chartDataApi.getTop10RegionRevenueAndUser(this.viewAllTime ? 0 : this.numberOfDateView).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.top10RegionRevenueAndUser.options.xAxis.categories = response.data.data.map(x => x.CountryName);
                        this.top10RegionRevenueAndUser.options.series = [{
                            name: "Revenue",
                            data: response.data.data.map(x => Math.round((((x.Revenue / x.TotalRevenue) * 100) + Number.EPSILON) * 100) / 100)
                        }, {
                            name: "Users",
                            data: response.data.data.map(x => Math.round((((x.QuantityOfUser / x.TotalUser) * 100) + Number.EPSILON) * 100) / 100)
                        }];
                        this.isLoadingChart[0] = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoadingChart[0] = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoadingChart[0] = false;
                });
            },
            getUserByCountry() {
                this.isLoadingChart[1] = true;
                chartDataApi.getUserByCountry(this.viewAllTime ? 0 : this.numberOfDateView).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.userRegionCircleChart.tableData = response.data.data;
                        this.userRegionCircleChart.options.series[0].data = response.data.data
                            //.filter(x => x.region !== "VN")
                            .map(x => ({ name: x.COUNTRY_NAME, y: x.COUNT_USERS }));
                        setTimeout(() => {
                            this.userRegionCircleChart.options.chart = {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                height: 500, // $('#userRegionCircleChartBlock').innerWidth(),
                                type: 'pie'
                            };
                        }, 2000);
                        this.isLoadingChart[1] = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoadingChart[1] = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoadingChart[1] = false;
                });
            },
            getRevenueByCountry() {
                this.isLoadingChart[2] = true;
                chartDataApi.getRevenueByCountry(this.viewAllTime ? 0 : this.numberOfDateView).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        response.data.data.splice(0, 1);
                        let sumRevenue = response.data.data.reduce((a, b) => a + b.revenue, 0);
                        this.revenueRegionCircleChart.options.series[0].data = response.data.data
                            //.filter(x => x.region !== "VN")
                            .map(x => ({ name: `${(x.region === 'FPTSOFT' ? 'FPT SOFTWARE' : (this.countryList[x.region] ? countryList[x.region] : 'OTHERS'))} ${x.region} (${(Math.round(((x.revenue / sumRevenue * 100) + Number.EPSILON) * 100) / 100)}%)`, y: x.revenue }));
                        setTimeout(() => {
                            this.revenueRegionCircleChart.options.chart = {
                                plotBackgroundColor: null,
                                plotBorderWidth: null,
                                plotShadow: false,
                                height: 500, //$('#userRegionCircleChartBlock').innerWidth(),
                                type: 'pie'
                            };
                        }, 2000);
                        this.isLoadingChart[2] = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoadingChart[2] = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoadingChart[2] = false;
                });
            },
        }
    }
</script>